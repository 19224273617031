//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DICTIONARY from '@mixins/dictionary.js'


export default {
    mixins: [ DICTIONARY ],
    props:[],
    components:{},
    data: function(){
        return{
            pgData:{
                kbstarttime:'',
                kbendtime:'',
            },
            tableData:[],
            partnerNamelist:[],
            tableHeight:'500',
            statusList:[
                {value:'未结算', key:'0',type:'info'},
                {value:'已结算', key:'1',type:'primary'},
                {value:'确认中', key:'2',type:'warning'},
                {value:'已确认', key:'3',type:'success'}
            ],
            isChangneiList:[
                {value:'否', key:'0',type:'info'},
                {value:'是', key:'1',type:'success'},
            ]
        }
    }, 
    computed:{
        valuetime:{
            get:function(){
                var startTime = this.pgData.kbstarttime || '';
                var endtime = this.pgData.kbendtime || '';
                    if(startTime && endtime){
                        return[
                            startTime,
                            endtime
                        ];
                    }else{
                        return[]
                    }
            },
            set:function(val){
                if(!val){
                    this.pgData.kbstarttime = '' ;
                    this.pgData.kbendtime = '';
                }else{
                    var startTime = val[0] || '';
                    var endtime = val[1] || '';
                    this.pgData.kbstarttime = startTime;
                    this.pgData.kbendtime = endtime;
                }
            },
        }  
    },
    watch:{
  
    },

    methods: {
        doSearch:function(){
            this.$refs.page.queryData();
        },
        //获取合作单位
        getPartnerName:function(){
            this.$get(`${this.getGetters('partnerUrl')}/channelInfo/listSelect`,{},function(data){
                this.partnerNamelist = data;
            })
        },
        //导出
        exportList:function(){
            var search = toSearch({
                kbstarttime:this.pgData.kbstarttime,
                kbendtime:this.pgData.kbendtime,
                partnerId:this.pgData.partnerId,
                zbdlname:this.pgData.zbdlname,
                kbarea:this.pgData.kbarea,
                bidsection:this.pgData.bidsection,
                orderAmountMix:this.pgData.orderAmountMix,
                orderAmountMax:this.pgData.orderAmountMax,
                isChangnei:this.pgData.isChangnei,
                settStatusYw:this.pgData.settStatusYw,
                settStatusHzs:this.pgData.settStatusHzs,
                settStatusFxs:this.pgData.settStatusFxs,
                isExport: true,
                token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token
            });
                window.open('/bhtong/ordermg/guaOrder' + search);
        },
        //跳转我的结算清单
        goStateList:function(){
            this.goto({
                path: '/settmg/settItem/list',
            })
        },
        afterQuery:function(){
            this.tableHeight = (window.innerHeight - 245);
        },
        
    },
    mounted() {
        this.getPartnerName();
    }
}
